import { forwardRef } from "react";

import { StackProps, VStack, useMultiStyleConfig } from "@chakra-ui/react";

const FullWidthVStack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const styles = useMultiStyleConfig("Common", {});
  return <VStack {...props} sx={styles.fullWidthVStack} ref={ref} />;
});
FullWidthVStack.displayName = "FullWidthStack";
export default FullWidthVStack;
